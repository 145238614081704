import * as React from "react"
import { Link } from "gatsby"

import Seo from "../components/seo"
import "../components/layout.css"

const IndexPage = () => (
  <div className="home_layout">
    <Seo title="Home" />
    <div className="home_cont">
      <h1>Welcome to Link&#x00B3;</h1>
      <h2>This site and domain is for private porpouse only.</h2>
      <p>For any information contact us at: </p>
      <p>
        <a
          href="mailto:honestguide.web@gmail.com"
          style={{ color: "darkblue" }}
        >
          honestguide.web@gmail.com
        </a>
      </p>
    </div>
    <footer>
      <p>powered by</p>
      <Link to="/">LINKCUBED</Link>
      <p>
        © {new Date().getFullYear()} linkcubed.com, built with{" "}
        <a href="https://www.gatsbyjs.com">Gatsby</a>
      </p>
    </footer>
  </div>
)

export default IndexPage
